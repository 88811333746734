import { Component } from 'vue-property-decorator';
import template from './GestionNegociateurs.Template.vue';
import { ApiService } from '@/services/base/ApiService';
import { Negociateur } from '@/models';
import { NegociateurCriteria } from './NegociateurCriteria.model';
import { PagingAndSorting } from '@/shared/models';
import { Mixins } from 'vue-mixin-decorator';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';

/**
 * Classe TS associée à la vue de gestion des négociateurs.
 */
@Component({
    ...template,
    name: 'GestionNegociateurs'
})
export default class GestionNegociateurs extends Mixins(GrilleMixin) {

    private negociateurs: Negociateur[];
    private headers: any[];
    private loading: boolean;
    public totalNegociateurs: number;
    public negociateurCriteria: NegociateurCriteria;

    constructor() {
        super();
        this.negociateurs = [];
        this.loading = false;
        this.negociateurCriteria = new NegociateurCriteria()
        this.totalNegociateurs = 0
    }

    public created() {
        this.headers = [
            { text: 'Nom', value: 'nom', class: 'text-xs-center' },
            { text: 'Prenom', value: 'prenom', class: 'text-xs-center' },
            { text: 'E-mail', value: 'mail', class: 'text-xs-center' },
            { text: 'Canal de collecte', value: 'entiteCommerciale', class: 'text-xs-center' },
            { text: 'Sous canal de collecte', value: 'equipe', class: 'text-xs-center' },
        ];
        this.getData();
    }

    public getData() {
        this.negociateurCriteria.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(this.pagination);
        this.getAllNegociateur(this.negociateurCriteria);
    }

    public getAllNegociateur(criteria: NegociateurCriteria) {
        this.loading = true;
        const fetchAllnegociateursApi = new ApiService<Negociateur>('negociateur/obtenirTousNegociateurs');

        fetchAllnegociateursApi.getWhere(criteria.queryString).then((response) => {
            this.negociateurs = response.data.data;
            this.totalNegociateurs = response.data.totalCount;
            this.$set(this.pagination, 'totalItems', this.totalNegociateurs);
        })
        .finally(() => this.loading = false);
    }
}